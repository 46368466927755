<template>
  <div class="">
    <h1>Powers</h1>
    <p>Without powers, you'd just be running around screaming. </p>
    <p>With powers, you'd be running and screaming, but also jabbing at 'Z' and 'X', thinking first: &quot;What's that supposed to do?&quot; then &quot;Well how does that help me?&quot; then &quot;Aha! I get it, I've just got to...&quot; then &quot;Oh... A bear ate me... Stupid game.&quot;</p>
    
	    <div class="block--img-right"><img src="@/assets/images/Power_Defuse.png" width="80" height="80" class="fltlft" /><strong>Defuse</strong><br/>
    Press 'Z' when near a bear trap to render it harmless.<br/>You can then collect it.<br/>
    Hold 'X' and an arrow key to place the trap.</div><div class="clearfloat"></div>
    
		<div class="block--img-right"><img src="@/assets/images/Power_Dig.png" width="80" height="80" class="fltlft" /><strong>Dig</strong><br/>
    Press 'Z' to dig downwards, leaving a hole in the floor.<br/>Digging while standing on stone lets you collect stone blocks.<br/>Place stone blocks using 'X'.</div><div class="clearfloat"></div>
    
        <div class="block--img-right"><img src="@/assets/images/Power_Freezer.png" width="80" height="80" class="fltlft" /><strong>Freezer</strong><br/>Press 'Z' to chill! Animals stop, fires extinguish, and water turns solid.</div><div class="clearfloat"></div>
        
        <div class="block--img-right"><img src="@/assets/images/Power_Jump.png" width="80" height="80" class="fltlft" /><strong>Jump</strong><br/>Hold down 'Z' and run into walls.<br/>I promise you won't bang your head.<br/>Teleport through solid matter.</div><div class="clearfloat"></div>
        
		<div class="block--img-right"><img src="@/assets/images/Power_Cloner.png" width="80" height="80" class="fltlft" /><strong>Cloner</strong><br/>Hold down 'X' then press arrow key to select a neighbouring block. Then go elsewhere and hold 'X' with an arrow key to place a copy.<br/>Pressing 'X' by itself cancels your clone block. You can't clone onto metal, voids or animals.</div><div class="clearfloat"></div>

		<div class="block--img-right"><img src="@/assets/images/Power_Push.png" width="80" height="80" class="fltlft" /><strong>Push</strong><br/>
    Hold down 'Z' and push objects.<br/>Stone and Wood blocks will move if they can.<br/>Some objects such as mirrors also move.<br />
		There is no limit to how many objects you can push at once. You are STRONG!</div><div class="clearfloat"></div>
		
        <div class="block--img-right"><img src="@/assets/images/Power_Pull.png" width="80" height="80" class="fltlft" /><strong>Pull</strong><br/>Hold down 'X' and press the arrow key in the direction of an object you would like to pull.<br/>It will turn see-through and then follow you until you release 'X'.<br/>You can push things this way too.</div><div class="clearfloat"></div>

		<div class="block--img-right"><img src="@/assets/images/Power_Stealth.png" width="80" height="80" class="fltlft" /><strong>Stealth</strong><br/>Nothing can see you now!<br/>Bears can't pursue, Cats can't flee.<br/>And you can't use mirrors to check your hair! Curses...</div><div class="clearfloat"></div>

		<div class="block--img-right"><img src="@/assets/images/Power_Shift.png" width="80" height="80" class="fltlft" /><strong>Shift</strong><br/>Congratulations: You have major tele-kinetic powers!<br/>Hold 'Z' and press an arrow key.<br/>All movable objects in that row or column will move.</div><div class="clearfloat"></div>

		<div class="block--img-right"><img src="@/assets/images/Power_Spiral.png" width="80" height="80" class="fltlft" /><strong>Spiral</strong><br/>More tele-kinetic powers!<br/>Press 'X' to create a spinny-vortex thing.</div><div class="clearfloat"></div>

		<div class="block--img-right"><img src="@/assets/images/Power_Skates.png" width="80" height="80" class="fltlft" /><strong>Skates</strong><br/>You know when you are skidding on ice, and would like to change direction...<br/>Well now you can!</div><div class="clearfloat"></div>

		<div class="block--img-right"><img src="@/assets/images/Power_CatFood.png" width="80" height="80" class="fltlft" /><strong>Cat Food</strong><br/>Look at those cats come to you now!<br/>Now they want your attention don't they.</div><div class="clearfloat"></div>

		<div class="block--img-right"><img src="@/assets/images/Power_Heater.png" width="80" height="80" class="fltlft" /><strong>Heater</strong><br/>Make the world warmer, melt the ice, burn animals! (Doesn't hurt cats you are carrying).<br/>Might want to find water soon after though.</div><div class="clearfloat"></div>

		<div class="block--img-right"><img src="@/assets/images/Power_Slippers.png" width="80" height="80" class="fltlft" /><strong>Slippers</strong><br/>
    With these slippers, nobody can hear you! Mines and traps won't spring, crumbly things won't crumble. (But avoid water).</div><div class="clearfloat"></div>

		<div class="block--img-right"><img src="@/assets/images/Power_All.png" width="80" height="80" class="fltlft" /><strong>All</strong><br/>
    Erm, yeah... Pretty much do what you want now. You have ALL powers!</div><div class="clearfloat"></div>
    </div>
</template>

<script>
export default {
  name: 'PageNews',
}
</script>

<style scoped>
</style>
