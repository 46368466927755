<template>
  <div class="footer align-centre">
    <a href="http://www.snappyfingers.co.uk">www.snappyfingers.co.uk</a>
    <a href="mailto:stuart@mazeofbears.com">stuart@mazeofbears.com</a>
  </div>
</template>

<script>
export default {
  name: 'FooterBlock',
}
</script>

<style scoped>
.footer a{
  margin-left:40px;
  color:white;
}
.footer{
  margin-top:80px;
  background-color: black;
  padding:40px;
}
@media only screen and (max-width: 768px) {
  .footer{
    padding:10px;
  }
  .footer a{
    margin-left: 0px;
    margin-top: 10px;
    display: block;
  }
}
</style>
