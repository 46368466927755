<template>
  <div id="nav" class="align-centre">
    <router-link to="/">Home</router-link> |
    <router-link to="/news">News</router-link> |
    <router-link to="/powers">Powers</router-link> |
    <router-link to="/animals">Animals</router-link> |
    <router-link to="/items">Items</router-link> |
    <router-link to="/images">Images</router-link> |
    <router-link to="/help">Help</router-link>
  </div>
</template>

<script>
export default {
  name: 'NavBlock'
}
</script>

<style scoped>
#nav {
  padding: 5px;
  margin-top:15px;
  margin-bottom:10px;
  background-color: black;
}

#nav a {
  font-weight: bold;
  color:white;
  text-decoration: none;
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 8px;
  margin-bottom: 8px;
  display: inline-block;
}
#nav a:hover{
  color:#df4ef8;
}

#nav a.router-link-exact-active {
  color: #b00dd1;
}
</style>
