<template>
  <div class="">
    <h1>News</h1>
    <div class="news-item">
      <p class="news-item--date">25/12/2021</p>
      <h2>Steam Launch 4th Jan 2022</h2>
      <p>Excitingness warning - Steam release has been approved and is now destined to explode out of the internet pipes on 4th Jan.</p>
      <p>Head on over to <a href="https://store.steampowered.com/app/1840850/Maze_of_Bears/">here</a> to add to your wishlist.</p>
      <p>Have decided already that if it sells less than 1 copy I'm going to quit games development, and if it sells more than 100,000 I shall quit the day job. Which will it be? Who can know such things?</p>
    </div>

    <div class="news-item">
      <p class="news-item--date">1/12/2021</p>
      <h2>Itch.io Release!</h2>
      <p>Maze of bears is now available to try/buy on itch.io! Check it out <a href="https://stuartbray.itch.io/maze-of-bears">here</a></p>
    </div>

    <div class="news-item">
      <p class="news-item--date">25/7/2021</p>
      <h2>Demo Released!</h2>
      <p>Public demo has now launched, available for all and sundry to play the first 36 levels of.</p>
      <p>Download the PC build <a href="https://snappy-fingers-maze-of-bears-web-gl.s3.eu-west-1.amazonaws.com/MazeOfBears_v0-02.zip">
        here</a>, and send feedback to <a href="mailto:stuart@mazeofbears.com?subject=Maze-Of-Bears%20feedback&body=I%20loved%20it%20but...">stuart@mazeofbears.com</a>. 
      </p>
    </div>

    <div class="news-item">
      <p class="news-item--date">3/4/2021</p>
      <h2>Playtesting!</h2>
      <p>Maze of Bears is now fully functional in beautiful Unity 3D with proper non-sucky graphics (ish). Time to start playtesting... </p>
      <p>Predictably, the first wave of feedback mostly concerned making it more obvious what the hell you are supposed to do. So I've added like, a million pop-up messages saying really patronising things like: "Press an arrow key to move!". Because if you don't do that people don't know that you are supposed to press arrow keys to move. Which is fine.</p>
      <p>But importantly, both sessions I had to stop after an hour because of time, not because testers got bored. Which is a good sign. If they had become bored after five minutes then maybe I would have dropped the project and moved onto something else. But they didn't, so I won't.</p>
    </div>
    <br/><br/>
    <div class="">
      <p>You can track some of the history of this project in the development blog on <a href="http://snappyfingers.co.uk/blog?filt=MazeOfBears">snappy fingers</a>, with entries dating back to 2012.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageNews',
}
</script>

<style scoped>
.news-item{
  margin-bottom: 20px;
  border-bottom: gray solid 1px;
}
.news-item--date{
  font-size: 14px;
  color:purple;
}
</style>
