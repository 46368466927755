<template>
  <div class="">
    <div class="align-centre">
      <img src="@/assets/images/MOB-BearRun-578x150-q50.gif" width="100%" height="auto" />
    </div>
    <p>Maze Of Bears is a <strong>wrapping</strong> grid-based puzzle game in which to find your lost cats, you must evade <strong>bears</strong>, <strong>bulls</strong>, <strong>spiders</strong>, <strong>wolves</strong>, <strong>lasers</strong> , <strong>land mines</strong>, <strong>fire</strong>, <strong>ice</strong>, <strong>pits</strong>, and <strong>bear traps</strong>. To solve each of the <strong>150  levels</strong> you'll learn how to <strong>push</strong>, <strong>pull</strong>, <strong>rubiks-slide</strong>, <strong>defuse</strong>, <strong>mirror-bounce</strong>, <strong>stealth</strong>, <strong>heat</strong>, <strong>freeze</strong>, <strong>climb</strong>, <strong>dig</strong>, <strong>teleport</strong>, <strong>tornado</strong>, <strong>clone</strong> and <strong>sneak</strong>.</p>
    <p>Every level has a new idea. Starting simply, you'll soon learn how to use each of the <strong>16 different bonus powers</strong> to manipulate the interactions between the <strong>30 different types</strong> of block. Things  slowly get very complicated, especially given that <strong>every level wraps</strong> the top and bottom, left and right of the screen. Fortunately the <strong>hint</strong> system spells out how to complete every level, so you won't get stuck.  </p>
    <p>Add to that a simple to use <strong>Level Editor</strong>, and a customisable <strong>Random Level Generator</strong>, so there's that...</p>

    <p class="align-centre">
	   <!-- <img src="@/assets/images/ScreenShot1.png" alt="mob" class="wide-image" style="border:1px solid #ddd;" /> -->
    <iframe width="560" height="315" src="https://www.youtube.com/embed/8UpfSBf7CSw?rel=0&fs=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </p>
	
    <div class="align-centre highlight-bar"> 
      Play it now on:
      <span class="chunky-btn">
        <a href="https://store.steampowered.com/app/1840850/Maze_of_Bears/" target="blank">
          Steam
        </a>
      </span>
    </div>
    <div class="align-centre"> 
      Also available on:
      <span class="chunky-btn">
        <a href="https://stuartbray.itch.io/maze-of-bears" target="blank">
          itch.io
        </a>
      </span>
      <p>
        The PC demo featuring the first 36 levels is free to play. Full version has 150 levels.
      </p>
      <p>
        Kindly email feedback to <a href="mailto:stuart@mazeofbears.com?subject=Maze-Of-Bears%20feedback&body=I%20loved%20it%20but...">stuart@mazeofbears.com</a> please, please, thank you.
      </p>
    </div>

    <br/><br/><br/><br/>
    <div class="align-centre">
      <p>Featured on <a href="https://gameskeys.net/top-steam-games-to-tryout-in-january-2022/">GamesKeys Top Steam games of 2022</a>.</p>
    </div>
  
  </div>

</template>

<script>
export default {
  name: 'PageHome',
}
</script>

<style scoped>
.chunky-btn{
  background-color: purple;
  /* border-radius: 25%; */
  display: inline-block;
  margin: 6px;
  padding:10px 12px;
  cursor: pointer;
  border-radius: 8px;
}
.chunky-btn a{
  text-decoration: none;
  color:white;
}
.highlight-bar{
  background-color: pink;
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>
