<template>
  <div id="app">
    <HeaderBlock></HeaderBlock>
    <NavBlock></NavBlock>
    <div class="mainContent">
      <router-view/>
    </div>
    <FooterBlock></FooterBlock>
  </div>
</template>

<script>
import HeaderBlock from "./components/HeaderBlock.vue"
import FooterBlock from "./components/FooterBlock.vue"
import NavBlock from "./components/NavBlock.vue"

export default {
  components: { HeaderBlock, FooterBlock, NavBlock }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;

  max-width: 900px;
  margin: 0 auto;
}

</style>
