<template>
  <div class="">
    <h1>Items</h1>
    <p>Without items our lives would be immeasurably poorer<strong></strong>. Some of the best things in life are items.</p>
    
	    <div class="block--img-right"><img src="@/assets/images/Icon_Bomb.png" width="110" height="110" class="fltlft" /><strong>Bomb</strong><br />
	     	Blows up after being pushed 4 times. Leaves a crater and four crumbly blocks. Pushes blocks.
	</div><div class="clearfloat"></div>
    
		<!-- <div class="block--img-right"><img src="@/assets/images/Icon_Chest.png"  width="110" height="110"  class="fltlft" /><strong>Chest</strong><br/>
			Randomly reveals a power. Stand near to open.
	</div><div class="clearfloat"></div> -->
    
        <div class="block--img-right"><img src="@/assets/images/Icon_Wood.png"  width="110" height="110" class="fltlft" /><strong>Crate</strong><br/>
        	Burnable. Floaty. Can be climbed on from a half block. Also available in half-size.<br />
            Crates are <strong>not</strong> game cliches, they are a vital part of storing and packing other game items. Re-using them for puzzles is sensible recycling.
    </div><div class="clearfloat"></div>
        
        <div class="block--img-right"><img src="@/assets/images/Icon_Fire.png" width="110" height="110"  class="fltlft" /><strong>Fire</strong><br/>
        	It Burns!!!<br/>
            And it spreads, consuming all but Stone and Metal. Leaves Ash in it's wake, which stops it spreading backwards, and can set up loops.<br/>
            Climb to safety if you can. Else jump in some Water quickly.
    </div><div class="clearfloat"></div>

        <div class="block--img-right"><img src="@/assets/images/Icon_Gold.png"  width="110" height="110"  class="fltlft" /><strong>Gold</strong><br/>
        	Collect all Gold and Cats to clear level.
    </div><div class="clearfloat"></div>
        
        <div class="block--img-right"><img src="@/assets/images/Icon_Ice.png"  width="110" height="110"  class="fltlft" /><strong>Ice</strong><br/>
        	Icy floors are slidy, unless you have Skates. Will melt into water with Heater power, as water will freeze into this with Freezer power.
    </div><div class="clearfloat"></div>
        
        <div class="block--img-right"><img src="@/assets/images/Icon_IceCube.png"  width="110" height="110"  class="fltlft" /><strong>IceCube</strong><br/>
        	Melty. Doesn't last long when exposed to air.
    </div><div class="clearfloat"></div>
        
        <div class="block--img-right"><img src="@/assets/images/Icon_Fixed.png" width="110" height="110" class="fltlft" /><strong>Metal</strong><br/>
        	Doesn't move. Even if you push it really hard. You can clone it, but can't remove it by cloning.<br/>
            Also available half size.
    </div><div class="clearfloat"></div>
        
        <div class="block--img-right"><img src="@/assets/images/Icon_Mine.png" width="110" height="110" class="fltlft" /><strong>Mine</strong><br/>
        	Triggers when you approach. Kills things. Pushes blocks. Leaves holes.
    </div><div class="clearfloat"></div>
        
        <div class="block--img-right"><img src="@/assets/images/Icon_Mirror.png" width="110" height="110" class="fltlft" /><strong>Mirror</strong><br/>
        	Bounces lasers. Push/Pullable. Rotatable by walking into it.
    </div><div class="clearfloat"></div>
        
        <div class="block--img-right"><img src="@/assets/images/Icon_Sand.png" width="110" height="110" class="fltlft" /><strong>Sand</strong><br/>
        	Shrinks when pushed. Can be climbed on when half-sized.
    </div><div class="clearfloat"></div>
        
        <div class="block--img-right"><img src="@/assets/images/Icon_Shooter.png" width="110" height="110" class="fltlft" /><strong>Shooter</strong><br/>
        	Fires burning lasers in all four directions.<br/>
            Can be walked on, built on, or pushed down holes.
    </div><div class="clearfloat"></div>
        
        <div class="block--img-right"><img src="@/assets/images/Icon_Wall.png" width="110" height="110" class="fltlft" /><strong>Stone</strong><br/>
        	Non-burny. Push/Pullable. Double-blocks split when pushed into water.<br/>
            You'll be safe from animals while running on top of blocks.
    </div><div class="clearfloat"></div>
        
        <div class="block--img-right"><img src="@/assets/images/Icon_Trap.png" width="110" height="110" class="fltlft" /><strong>Trap</strong><br/>
        	Don't put your toes anywhere near this one. Worth luring Bears, Wolves and Spiders into.<br/>
            Can be deactivated by standing near it and using the Defuse power, then picked up and set elsewhere.<br/>
            Burns. Can be pushed down holes, or into water.
    </div><div class="clearfloat"></div>
        
        <div class="block--img-right"><img src="@/assets/images/Icon_Tree.png" width="110" height="110" class="fltlft" /><strong>Tree</strong><br/>
        	Cats hide in trees until all the aggressive wildlife has been cleared.<br/>
            Don't let fire or lasers touch it until you've collected the cat from it.
    </div><div class="clearfloat"></div>
        
        <div class="block--img-right"><img src="@/assets/images/Icon_Water.png" width="110" height="110" class="fltlft" /><strong>Water</strong><br/>
        	Wet.
    Non-lethal. Slows you down. Ruins Slippers or Stealth.<br />
    Cats aren't fans.<br />
        The only thing that can fill a void.<br />
        Can be reclaimed by pushing Stone blocks into it.
        </div>
        <div class="clearfloat"></div>
        
        <div class="block--img-right"><img src="@/assets/images/Icon_Void.png" width="110" height="110" class="fltlft" /><strong>Void</strong><br/>
        	Holey.<br />
        	Shove stuff you don't want down it.
    </div>
        <div class="clearfloat"></div>
        

		
    <p>&nbsp;</p>
  </div>
</template>

<script>
export default {
  name: 'PageNews',
}
</script>

<style scoped>
</style>
