import { render, staticRenderFns } from "./PageItems.vue?vue&type=template&id=f6142290&scoped=true&"
import script from "./PageItems.vue?vue&type=script&lang=js&"
export * from "./PageItems.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f6142290",
  null
  
)

export default component.exports