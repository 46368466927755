<template>
  <div class="">
    <h1>Animals</h1>
    <p>We exist in a fragile eco-system, ultimately our entire bio-sphere is symbiotic. That said, if threatened by <strong>Bears</strong> etc, it is ethically acceptable to defend oneself with <strong>Explosives</strong>.</p>

    <div class="block--img-right"><img src="@/assets/images/Icon_Bear.png" width="110" height="110" class="fltlft" /><strong>Bear</strong><br />
    Growly. Avoid-worthy. Flammable. Swimmy. Trappable.<br />
    They move at a steady pace in your direction, but are easy to outrun.<br />
    Notoriously claustrophobic, they don't like being confined.
    </div><div class="clearfloat"></div>

    <div class="block--img-right"><img src="@/assets/images/Icon_Wolf.png" width="110" height="110" class="fltlft" /><strong>Wolf</strong><br/>
    Moves when you do. Once they are on your trail, they are difficult to shake.<br />
    Climbing out of danger often helps. As do traps, lasers, mines etc.
    <br />
    </div><div class="clearfloat"></div>

    <div class="block--img-right"><img src="@/assets/images/Icon_Bull.png" width="110" height="110" class="fltlft" /><strong>Bull</strong><br/>
    Chargy. Pointy. Turns to Right if it has to. Flam-a-bull. Pushes blocks. Non-swimmer.<br />
    Isn't really bothered by your existence, just likes to run around like an idiot.
    </div><div class="clearfloat"></div>

    <div class="block--img-right"><img src="@/assets/images/Icon_Spider.png" width="110" height="110" class="fltlft" /><strong>Spider</strong><br/>
    Jumps like a Chess Knight. Blind, reacts to sound. Fooled by slippers not invisibility.<br />
    Don't just run away, or you will get eaten. Instead look where it can jump next, and avoid that.<br />
    If you stay on the walls, and run around near voids, lasers or traps, they will fall for it every time.
    </div><div class="clearfloat"></div>

    <div class="block--img-right"><img src="@/assets/images/Icon_Hatchling.png" width="110" height="110" class="fltlft" /><strong>Hatchling</strong><br/>
    Moves diagonally one square. Blind, reacts to sound. Fooled by slippers not invisibility.<br />
    These are different to spiders, and not just a lazy re-use of artwork.
    </div><div class="clearfloat"></div>

    <div class="block--img-right"><img src="@/assets/images/Icon_Cat.png" width="110" height="110" class="fltlft" /><strong>Cat</strong><br/>
    Stupid animal. Why is it running away? You're here to save them!<br />
    A bit of cat food will attract them.<br />
    Immune to lasers, traps, or other animals. <br />
    But they don't like being set on fire, or pushed down holes. (Which is understandable).<br />
    Sometimes they snooze, which makes them easier to catch.<br />
    If you get wet, any collected cats escape.
    </div><br />
  </div>
</template>

<script>
export default {
  name: 'PageNews',
}
</script>

<style scoped>
</style>
