<template>
  <div class="">
    <div class="align-centre">
      <h1>Images</h1>
    </div>
    <div class="align-centre image-list">
      <img src="@/assets/images/MOB-BearRun-578x150-q50.gif" />
      <img src="@/assets/images/promotion/cover-image.png" />
      <img src="@/assets/images/promotion/HeaderCapsule.png" />
      <img src="@/assets/images/promotion/library_capsule.png" />
      <img src="@/assets/images/promotion/library_hero.png" />
      <img src="@/assets/images/promotion/maze-of-bears-logo.png" /> 
      <img src="@/assets/images/promotion/gameplay1.png" />
      <img src="@/assets/images/promotion/gameplay2.png" />
      <img src="@/assets/images/promotion/gameplay3.png" />
      <img src="@/assets/images/promotion/gameplay4.png" />
      <img src="@/assets/images/promotion/gameplay5.png" />
      <img src="@/assets/images/promotion/gameplay6.png" /> 
      <img src="@/assets/images/promotion/gameplay7.png" /> 
      <img src="@/assets/images/promotion/gameplay8.png" /> 
      <img src="@/assets/images/promotion/gameplay9.png" />
      <img src="@/assets/images/promotion/gameplay10.png" />
      <img src="@/assets/images/promotion/gameplay11.png" />
      <img src="@/assets/images/promotion/Just-Bear.png" />
      <img src="@/assets/images/promotion/Just-Bull.png" />
      <img src="@/assets/images/promotion/Just-Wolf.png" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageImages',
}
</script>

<style scoped>
.image-list img{
  width:100%;
  height:auto;
}
</style>
