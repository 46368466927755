<template>
  <div class="">
    <h1>Help</h1>
    <p><span class="question">I'm stuck on level x!</span></p>
    <p>There is a hint system. Just press the hint button at the bottom right. Some levels have many hints, starting vague, and getting steadily patronisinger. Or just skip the level, which is allowed.</p>
    <p class="question">What Platforms is it on?</p>
    <p>Unity for PC and Mac.</p>
    <!-- <p>You shouldn't need a super fast machine to play it, but if you aren't sure if it will run smoothly on the bigger levels, just try running around a 'Random Play' level and see if the framerate dips.</p> -->
    <p class="question">I found a bug! / I have advice / complaint / praise etc...</p>
    <p>Hit me up on <a href="mailto:stuart@mazeofbears.com">stuart@mazeofbears.com</a>. Greatly appreciated.</p>
    <p class="question">Why all the animal death?</p>
    <p>If it helps, you could picture them as Alien / Robot / Pirate / Zombie Bears. That might ethically justify the use of explosives. </p>  </div>
</template>

<script>
export default {
  name: 'PageNews',
}
</script>

<style scoped>
.question{
  font-weight: bold;
}
</style>
