import Vue from 'vue'
import VueRouter from 'vue-router'

import PageAnimals from '../views/PageAnimals.vue';
import PageHelp from '../views/PageHelp.vue';
import PageHome from '../views/PageHome.vue';
import PageItems from '../views/PageItems.vue';
import PageImages from '../views/PageImages.vue';
import PageNews from '../views/PageNews.vue';
import PagePowers from '../views/PagePowers.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: PageHome
  },
  {
    path: '/home',
    name: 'Home',
    component: PageHome
  },
  {
    path: '/animals',
    name: 'animals',
    component: PageAnimals
  },
  {
    path: '/help',
    name: 'help',
    component: PageHelp
  },
  {
    path: '/items',
    name: 'items',
    component: PageItems
  },
  {
    path: '/images',
    name: 'images',
    component: PageImages
  },
  {
    path: '/news',
    name: 'news',
    component: PageNews
  },
  {
    path: '/powers',
    name: 'powers',
    component: PagePowers
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
