<template>
  <div class="header align-centre">
    <a href="/">
    <img class="logo" src="@/assets/images/MazeOfBears_LogoLong.png" />
    </a>
  </div>
</template>

<script>
export default {
  name: 'HeaderBlock',
}
</script>

<style scoped>
.header .logo{
  margin-top:5px;
}
@media only screen and (max-width: 768px) {
  .header .logo{
    width:80vw;
  }
}
</style>
